<template>
  <!-- 상단 메뉴 시작 -->
  <div class="main-navi-wrap non-print">
    <!-- gnb-menu -->
    <div
      class="main-gnb-menu"
      :class="{ is_color: gnb.isToolbarColor == true }"
    >
      <div class="gnb-wrap">
        <div
          class="gnb-wrap-fast"
          :class="{ is_gnb_color: gnb.isToolbarColor == true }"
        >
          <div class="gnb-fast">
            <ul v-if="gnb.isLoaded == true" class="gnb-fast-ul">
              <div class="gnb-fast-ul-wrap">
                <ul class="gnb-ul">
                  <li
                    @click="gnb.gnbMenuCat1()"
                    @mouseenter="gnb.gnbMenuCat1()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    업무작성 및 요청
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat1">
                      <li @click="gnb.moveLink('BusinessManagement-ERList')">
                        E.R
                      </li>
                      <li @click="gnb.moveLink('TaskManagement-taskList')">
                        업무함 관리
                      </li>
                      <li
                        @click="
                          gnb.moveExternalLink(
                            'https://carrotsds.atlassian.net/servicedesk/customer/portal/8'
                          )
                        "
                      >
                        개발연구소 업무협조
                      </li>
                      <!-- <li
                        @click="
                          gnb.moveExternalLink(
                            'https://carrotsds.atlassian.net/servicedesk/customer/portal/9'
                          )
                        "
                      >
                        컨텐츠플랫폼 업무협조
                      </li> -->
                      <li
                        @click="
                          gnb.moveLink('BusinessManagement-ManagementPlanList')
                        "
                      >
                        경영기획 업무협조
                      </li>
                      <!-- <li
                        v-if="store.state.isDevelopmentTeam"
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-MagazineManagementList'
                          )
                        "
                      >
                        사보 관리
                      </li> -->
                      <li
                        v-if="store.state.isSaboArchiving"
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-NewsLetterManagementList'
                          )
                        "
                      >
                        사보 당근농장 관리
                      </li>
                    </ul>
                  </li>

                  <li
                    @click="gnb.gnbMenuCat2()"
                    @mouseenter.stop="gnb.gnbMenuCat2()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    My PIMS
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat2">
                      <li @click="gnb.moveLink('myPIMS-myVacationList')">
                        나의 휴가관리
                      </li>
                      <li @click="gnb.moveLink('myPIMS-myEduManagemetList')">
                        나의 교육관리
                      </li>
                      <li @click="gnb.moveLink('myPIMS-PBCKPIList')">
                        나의 업적관리(PBC/KPI)
                      </li>
                      <li @click="gnb.moveLink('myPIMS-MyCommunicationPledge')">
                        나의 커뮤니케이션 서약
                      </li>
                      <li @click="gnb.moveLink('myPIMS-myAssetList')">
                        나의 자산관리
                      </li>
                      <li
                        @click="
                          gnb.moveExternalLink(
                            'https://www.ipayview.com/index.asp'
                          )
                        "
                      >
                        급여센터
                      </li>
                    </ul>
                  </li>

                  <li
                    @click="gnb.gnbMenuCat3()"
                    @mouseenter.stop="gnb.gnbMenuCat3()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    전자결재
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat3">
                      <li @click="gnb.moveLink('documents-AllDocumentList')">
                        결재 리스트
                      </li>
                      <li @click="gnb.moveLink('documents-DeptInboxList')">
                        우리 부서수신함
                      </li>
                      <li
                        @click="gnb.moveLink('documents-MyExpenseManagement')"
                      >
                        내 경비관리
                      </li>
                      <li
                        @click="gnb.moveLink('documents-MyExtraPayManagement')"
                      >
                        내 수당관리
                      </li>

                      <!-- 리더, 회계팀 권한 경우에만 노출 -->
                      <li
                        v-if="store.state.isSalesAdmin || store.state.isLead"
                        @click="gnb.moveLink('documents-AllExpenseList')"
                      >
                        전체 경비관리
                      </li>
                      <li
                        v-if="store.state.isSalesAdmin || store.state.isLead"
                        @click="gnb.moveLink('documents-AllExtraPayList')"
                      >
                        전체 수당관리
                      </li>

                      <li
                        @click="
                          gnb.moveLink('documents-SalesManagementPublishList')
                        "
                      >
                        세금계산서 발행 등록 관리
                      </li>
                      <li
                        @click="
                          gnb.moveLink(
                            'documents-SalesManagementNotPublishList'
                          )
                        "
                      >
                        세금계산서 미발행 관리(ITM)
                      </li>
                      <li
                        @click="gnb.moveLink('documents-TotalSalesAmountList')"
                      >
                        전체매출 계산서 발행 현황보기
                      </li>
                    </ul>
                  </li>
                  <li
                    @click="gnb.gnbMenuCat4()"
                    @mouseenter.stop="gnb.gnbMenuCat4()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    자원관리
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat4">
                      <li
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-VehicleReservationLogDay'
                          )
                        "
                      >
                        차량운행 예약하기
                      </li>
                      <li
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-ClassroomReservationDate'
                          )
                        "
                      >
                        강의장 예약하기
                      </li>
                      <li
                        @click="
                          gnb.moveLink('ResourceManagement-TextbookRentalList')
                        "
                      >
                        교재출고 요청하기
                      </li>
                      <li
                        @click="
                          gnb.moveLink('ResourceManagement-TutorTextbookList')
                        "
                      >
                        강사용 교재 신청하기
                      </li>
                      <a @click="gnb.goDelivery" target="_blank"
                        ><li>택배발송 예약하기</li></a
                      >
                      <!-- <li @click="gnb.moveLink('ResourceManagement-MIScheduleDate')">전임교수(MI) 스케줄보기</li> -->
                      <li
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-StudioReservationMonth'
                          )
                        "
                      >
                        촬영실 운용현황보기
                      </li>
                      <li @click="gnb.moveLink('myPIMS-GoodsRentalList_tab1')">
                        물품대여 신청하기
                      </li>
                      <!-- Ashley님,Sean님,James님,Jessica님,Louis님,Aiden님,Katie님 cms 권한 -->
                      <li
                        v-if="store.state.isCmsAdmin"
                        @click="gnb.moveLink('myPIMS-eicn')"
                      >
                        통화관리시스템(CMS)
                      </li>
                      <!-- <li @click="gnb.moveLink('ResourceManagement-VacationManagement')">계정관리</li> -->
                    </ul>
                  </li>

                  <!-- 권한 메뉴  -->
                  <li
                    v-if="
                      store.state.isLead ||
                      store.state.pd_auth ||
                      store.state.isManageSupportAdmin ||
                      store.state.isSalesAdmin
                    "
                    @click="gnb.gnbMenuCat5()"
                    @mouseenter.stop="gnb.gnbMenuCat5()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    인사관리
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat5">
                      <!-- 리더 이상, 피플팀 추가 -->
                      <li
                        v-if="store.state.isLead || store.state.pd_auth"
                        class="bold gnb-sub-menu-title"
                      >
                        팀 관리
                      </li>
                      <li
                        v-if="store.state.isLead || store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-IncumbentManagementList')"
                      >
                        재직자 관리
                      </li>
                      <li
                        v-if="store.state.isLead || store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-EducationDashboard')"
                      >
                        팀 교육 관리
                      </li>
                      <li
                        v-if="store.state.isLead"
                        @click="
                          gnb.moveLink('myPIMS-TeamEvaluationManagementList')
                        "
                      >
                        팀 업적 평가 관리
                      </li>
                      <li
                        v-if="store.state.isLead || store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-TeamVacationList')"
                      >
                        팀 휴가 관리
                      </li>

                      <!-- 피플팀 권한 -->
                      <li
                        v-if="
                          store.state.pd_auth ||
                          store.state.isManageSupportAdmin
                        "
                        class="bold gnb-sub-menu-title"
                      >
                        피플팀 업무
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-HeadTeamManagement')"
                      >
                        조직도 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-RecruitList')"
                      >
                        지원자 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="
                          gnb.moveLink('myPIMS-JobRotationManagementList')
                        "
                      >
                        Job Rotation 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-EducationList')"
                      >
                        전사 교육 관리
                      </li>
                      <!-- <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-FeedbackList')"
                      >
                        MCG/MCR 관리
                      </li> -->
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-MCGList')"
                      >
                        MCG 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-MCRList')"
                      >
                        MCR 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-CommunicationPledge')"
                      >
                        커뮤니케이션 서약 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-TelecommutingList')"
                      >
                        재택근무자 관리
                      </li>
                      <li
                        v-if="store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-HolidayList')"
                      >
                        공휴일 관리
                      </li>
                      <!-- 총무팀도 DISC 페이지에 접속 가능하도록 추가. -->
                      <li
                        v-if="
                          store.state.pd_auth ||
                          store.state.isManageSupportAdmin
                        "
                        @click="gnb.goDISC"
                      >
                        DISC
                      </li>

                      <!-- 총무팀 권한 -->
                      <li
                        v-if="
                          store.state.isManageSupportAdmin ||
                          store.state.isPlanningTeam
                        "
                        class="bold gnb-sub-menu-title"
                      >
                        총무팀 업무
                      </li>
                      <li
                        v-if="
                          store.state.isManageSupportAdmin ||
                          store.state.isSalesAdmin
                        "
                        @click="gnb.moveLink('myPIMS-AssetManagementList')"
                      >
                        자산 및 물품 대여 관리
                      </li>
                      <li
                        v-if="
                          store.state.isManageSupportAdmin ||
                          store.state.isSalesAdmin
                        "
                        @click="
                          gnb.moveLink('myPIMS-AssetManagementReportList')
                        "
                      >
                        재물조사 관리
                      </li>
                      <li
                        v-if="store.state.isManageSupportAdmin"
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-textbookMaintenanceList'
                          )
                        "
                      >
                        교재 관리
                      </li>
                      <li
                        v-if="
                          store.state.isManageSupportAdmin ||
                          store.state.isPlanningTeam
                        "
                        @click="gnb.moveLink('myPIMS-HoliDayGift')"
                      >
                        명절 선물 관리
                      </li>
                      <!-- <li v-if="store.state.isManageSupportAdmin" @click="gnb.moveLink('ResourceManagement-MagazineManagementList')">사보 관리</li>-->

                      <!-- 루이스님 전체권한, 휴가관리는 피플팀 권한 추가(루이스님 요청.) -->
                      <li
                        v-if="store.state.isHrAdmin || store.state.pd_auth"
                        class="bold gnb-sub-menu-title"
                      >
                        휴가 및 기타 업무
                      </li>
                      <li
                        v-if="store.state.isHrAdmin"
                        @click="
                          gnb.moveLink(
                            'ResourceManagement-FuelCostMangementList'
                          )
                        "
                      >
                        유류비 관리
                      </li>
                      <li
                        v-if="store.state.isHrAdmin || store.state.pd_auth"
                        @click="gnb.moveLink('myPIMS-VacationManagement')"
                      >
                        휴가 관리
                      </li>

                      <li
                        v-if="store.state.isManageDisposalAdmin"
                        class="bold gnb-sub-menu-title"
                      >
                        인사 관리
                      </li>
                      <li
                        v-if="store.state.isManageDisposalAdmin"
                        @click="gnb.moveLink('myPIMS-AssetDisposalList')"
                      >
                        폐기요청리스트
                      </li>
                    </ul>
                  </li>

                  <li
                    @click="gnb.gnbMenuCat6()"
                    @mouseenter.stop="gnb.gnbMenuCat6()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    고객사 관리
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat6">
                      <li
                        @click="
                          gnb.moveLink('customerManagement-scheduleVisitWeekly')
                        "
                      >
                        현장경영 스케줄
                      </li>
                      <li
                        @click="
                          gnb.moveLink('customerManagement-customerDBList')
                        "
                      >
                        고객사(DB)/상담일지
                      </li>
                      <li
                        @click="
                          gnb.moveLink('customerManagement-businessCardList')
                        "
                      >
                        명함관리
                      </li>
                      <li
                        @click="
                          gnb.moveLink('customerManagement-statusSMPList')
                        "
                      >
                        전략마케팅 처리현황
                      </li>

                      <!-- 리더권한 -->
                      <li
                        v-if="store.state.isLead"
                        @click="
                          gnb.moveLink(
                            'customerManagement-statusCarrotTotalSalesList'
                          )
                        "
                      >
                        고객사 별 매출순위
                      </li>
                      <a
                        @click="gnb.goHRD"
                        target="_blank"
                        v-if="store.state.isHRDSite"
                        ><li>컨퍼런스 운영</li></a
                      >
                      <a
                        @click="gnb.goNewHRD"
                        target="_blank"
                        v-if="store.state.isHRDSite"
                        ><li>New 컨퍼런스 운영</li></a
                      >
                    </ul>
                  </li>
                  <li
                    @click="gnb.gnbMenuCat7()"
                    @mouseenter.stop="gnb.gnbMenuCat7()"
                    @mouseleave="gnb.gnbMenuReset()"
                    class="gnb-ul-1dep"
                  >
                    CARROT ZONE
                    <ul class="gnb-sub-menu" v-if="gnb.gnb_menu_cat7">
                      <li @click="gnb.moveLink('CARROTZone-NoticeList')">
                        공지사항
                      </li>
                      <li @click="gnb.moveLink('CARROTZone-MediaZoneList')">
                        Media Zone
                      </li>
                      <li @click="gnb.moveLink('CARROTZone-CEOMessageList')">
                        CEO Message
                      </li>
                      <li @click="gnb.moveLink('CARROTZone-CarrotManualList')">
                        CARROT Manual
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="float-right border">
                  <li @click="gnb.goCTM">CTM</li>
                  <li @click="gnb.goITM">ITM</li>
                  <li @click="gnb.goSPAC">SPAC</li>
                  <li @click="gnb.goSMS">SMS</li>
                  <li @click="gnb.goMail">Mail</li>
                </ul>
              </div>
              <div
                class="float-right"
                @mouseenter="gnb.showMyBoxList = !gnb.showMyBoxList"
                @mouseleave="gnb.showMyBoxList = !gnb.showMyBoxList"
              >
                <ul>
                  <li
                    class="user-box"
                    @click="gnb.showMyBoxList = !gnb.showMyBoxList"
                  >
                    <img
                      class="my-profile"
                      :src="gnb.info.photouri"
                      :alt="gnb.info.ename + '\'s profile image'"
                    />
                    <p class="user-box-user">
                      {{ gnb.info.ename }} &nbsp;
                      <span>
                        {{
                          gnb.info.team_name
                            ? gnb.info.team_name
                            : gnb.info.office_name
                        }}
                      </span>
                    </p>
                    <img
                      src="@/assets/img/arrow-btn.png"
                      alt="Show my box"
                      class="mt-10"
                      v-if="!gnb.isToolbarColor"
                    />
                    <img
                      src="@/assets/img/arrow-btn-white.png"
                      alt="Show my box"
                      class="mt-10"
                      v-if="gnb.isToolbarColor"
                    />
                  </li>
                  <li class="my-box" v-show="gnb.showMyBoxList">
                    <div v-if="gnb.isLoaded == true" class="my-box-list">
                      <div
                        @click="gnb.showMyBoxList = !gnb.showMyBoxList"
                        class="my-box-bg"
                      ></div>
                      <ul>
                        <router-link to="/documents/AllDocumentList?doc_type=T1"
                          ><li>
                            결재할 문서
                            <p>
                              <span
                                v-if="gnb.info.sign.is_new == 'Y'"
                                class="my-box-list-new"
                                >N</span
                              >
                              <span class="my-box-list-count">
                                {{ gnb.info.sign.count }}</span
                              >
                            </p>
                          </li></router-link
                        >
                        <router-link to="/documents/AllDocumentList?doc_type=P2"
                          ><li>
                            완료된 문서
                            <p>
                              <span
                                v-if="gnb.info.done.is_new == 'Y'"
                                class="my-box-list-new"
                                >N</span
                              ><span class="my-box-list-count">
                                {{ gnb.info.done.count }}</span
                              >
                            </p>
                          </li></router-link
                        >
                        <router-link to="/documents/AllDocumentList?doc_type=T3"
                          ><li>
                            참조된 문서
                            <p>
                              <span
                                v-if="gnb.info.referenced.is_new == 'Y'"
                                class="my-box-list-new"
                                >N</span
                              ><span class="my-box-list-count">
                                {{ gnb.info.referenced.count }}</span
                              >
                            </p>
                          </li></router-link
                        >
                        <router-link to="/documents/AllDocumentList?doc_type=P3"
                          ><li>
                            반려된 문서
                            <p>
                              <span
                                v-if="gnb.info.returned.is_new == 'Y'"
                                class="my-box-list-new"
                                >N</span
                              ><span class="my-box-list-count">
                                {{ gnb.info.returned.count }}</span
                              >
                            </p>
                          </li></router-link
                        >
                        <router-link to="/documents/DeptInboxList"
                          ><li>
                            우리 부서수신함
                            <p>
                              <span
                                v-if="gnb.info.received.is_new == 'Y'"
                                class="my-box-list-new"
                                >N</span
                              ><span class="my-box-list-count">
                                {{ gnb.info.received.count }}</span
                              >
                            </p>
                          </li></router-link
                        >
                        <!-- <li>알림 <p><span v-if="gnb.info.notice.is_new=='Y'"           class="my-box-list-new">N</span><span class="my-box-list-count"> {{ gnb.info.notice.count }}</span></p></li> -->
                        <li @click="gnb.moveLink('myPIMS-myVacationList')">
                          잔여 연차
                          <p>
                            <span
                              v-if="gnb.info.vacation.is_new == 'Y'"
                              class="my-box-list-new"
                              >N</span
                            ><span class="my-box-list-count">
                              {{ gnb.info.vacation.count_txt }}</span
                            >
                          </p>
                        </li>
                        <li @click="gnb.moveLink('myPIMS-myEduManagemetList')">
                          교육 학점
                          <p>
                            <span
                              v-if="gnb.info.education.is_new == 'Y'"
                              class="my-box-list-new"
                              >N</span
                            ><span class="my-box-list-count">
                              {{ gnb.info.education.grade_txt }}</span
                            >
                          </p>
                        </li>

                        <li @click="pop.showThanksDollar">
                          감사 달러
                          <p>
                            <span
                              v-if="gnb.info.thanks.is_new == 'Y'"
                              class="my-box-list-new"
                              >N</span
                            ><span class="my-box-list-count">
                              {{ gnb.info.thanks.dollar_txt }}</span
                            >
                          </p>
                        </li>
                        <li  v-if="gnb.rotation.is_show_jobrotation">
                          <span  @click="pop.showJobRotation">Job Rotation </span>
                        </li>
                        <li @click="pop.showProfileMod">설정</li>
                        <li @click="gnb.doLogout">logout</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <div
          class="gnb-mid-wrap"
          :class="{ is_color: gnb.isToolbarColor == true }"
        >
          <div class="gnb-mid">
            <div class="logo">
              <h1 v-if="gnb.isToolbarColor == false">
                <router-link to="/main"
                  ><img src="@/assets/img/logo.png" alt=""
                /></router-link>
              </h1>
              <!-- 롤링 공지사항 -->
              <div class="main-line-notice" v-if="gnb.isToolbarColor == false">
                <ul
                  :class="{ reverse: roll.is_reverse == true }"
                  class="notice-list"
                >
                  <template v-for="(irow, i) in roll.list" :key="i">
                    <li
                      @click="roll.moveLink(irow.idx)"
                      :class="{
                        prevroll: roll.prev == irow.idx,
                        currentroll: roll.curr == irow.idx,
                        nextroll: roll.next == irow.idx,
                      }"
                    >
                      <span class="notice">Notice</span>
                      <span class="notice-txt">{{ irow.title }}</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="float-right">
              <div
                class="dp-inblock ver-top mt-25 mr-10 pt-2 font-12 txt-right"
                style="color: #fff"
              >
                <a
                  :href="gnb.old_carrot_link"
                  target="_balnk"
                  :class="{ oldcarrot: gnb.isToolbarColor == false }"
                  style="color: #fff"
                  >구 당근농장 가기</a
                ><br />
                <a
                  :href="gnb.old_mailplug_link"
                  target="_balnk"
                  :class="{ oldcarrot: gnb.isToolbarColor == false }"
                  style="color: #fff"
                  >구 이메일 가기</a
                >
                <div
                  @click="onstaff.showAll()"
                  class="pointer pt-4"
                  :class="{ oldcarrot: gnb.isToolbarColor == false }"
                >
                  사내연락망 보기
                </div>
                <!-- <p class="txt-white pt-4" :class="{ 'oldcarrot':gnb.isToolbarColor == false }">접속중인 당근인 : {{onstaff.total_on}}명</p> -->
              </div>
              <div
                class="dp-inblock"
                @mouseenter="onstaff.checkStaff"
                @mouseleave="onstaff.closed"
              >
                <div class="search-box clear">
                  <div class="onwer clear">
                    <img src="@/assets/img/onwer_person.png" alt="" />
                    <p>{{ onstaff.total_on }}명</p>
                  </div>
                  <input
                    type="text"
                    placeholder="접속중인 당근인"
                    class="find-user-input"
                    v-model="onstaff.find_staff"
                    @focus="onstaff.checkStaff"
                    @keyup="onstaff.checkStaff"
                  />
                  <img
                    src="@/assets/img/search.png"
                    alt=""
                    class="find-user-img"
                  />
                </div>
                <div class="connected_staff" v-if="onstaff.showPop">
                  <!-- <div @click="onstaff.showAll()" class="staff-list">사업부 전체 연락처 보기</div> -->
                  <div class="staff-box">
                    <template v-for="(s, idx) in onstaff.list" :key="idx">
                      <div
                        class="staff-info"
                        :class="{ on: s.is_on == 'Y' }"
                        v-if="
                          s.ename
                            .toUpperCase()
                            .indexOf(onstaff.find_staff.toUpperCase()) == 0 ||
                          s.kname
                            .toUpperCase()
                            .indexOf(onstaff.find_staff.toUpperCase()) >= 0
                        "
                      >
                        <div
                          class="icon-dollar"
                          @click="pop.showThanksDollar(s.idx)"
                        >
                          <img
                            v-if="s.is_thanks == 'N'"
                            src="@/assets/img/dollar-sign-solid-off.png"
                          />
                          <img
                            v-if="s.is_thanks == 'Y'"
                            src="@/assets/img/dollar-sign-solid.png"
                          />
                        </div>

                        {{ s.ename }}({{ s.kname }})<br />
                        {{ s.mp3code }} <span v-if="s.mp3code != ''"> / </span>
                        {{ s.phone }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <JobRotationPopup
      v-if="pop.isShowJobRotation"
      @close="pop.hideJobRotation"
    ></JobRotationPopup>
    <ThanksDollarViewPopup
      v-model:showList.sync="pop.isShowThxDollarList"
      v-if="pop.isShowThxDollarView"
      @close="pop.hideThanksDollar"
    ></ThanksDollarViewPopup>
    <ThanksDollarListPopup
      v-if="pop.isShowThxDollarList"
      @close="pop.hideThxDollarList"
      :idx_hq="pop.targetShowThxDollarIdx"
    ></ThanksDollarListPopup>
    <ProfileModPopup
      v-if="pop.isShowProfileMod"
      @close="pop.hideProfileMod"
    ></ProfileModPopup>
  </div>
  <!-- 상단 메뉴 끝 -->
  <!-- <div class="float-bn" style="z-index: 999" v-if="banner.isVisible">
        <a href="https://forms.gle/Rdysyruo66Myq6Xr6" target="_blank" > <img src="@/assets/img/survey-bn.png"></a>
        <a @click="banner.close()" style="position:relative;top:-100px;left:-20px; cursor:pointer;font-size:15px; font-weight: bold"> x</a>
    </div> -->
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
// import CarrotStaff from '@/components/common/CarrotStaff.vue';
import JobRotationPopup from "@/components/popup/myPIMS/JobRotationPopup.vue";
import ThanksDollarViewPopup from "@/components/popup/personality/ThanksDollarViewPopup.vue";
import ThanksDollarListPopup from "@/components/popup/personality/ThanksDollarListPopup.vue";
import ProfileModPopup from "@/components/popup/personality/ProfileModPopup.vue";

import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
  name: "ReumHeader",
  components: {
    // CarrotStaff,
    JobRotationPopup,
    ThanksDollarViewPopup,
    ThanksDollarListPopup,
    ProfileModPopup,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const cookies = useCookies();

    const pop = reactive({
      // Popup 노출 여부
      isShowJobRotation: false,
      isShowThxDollarView: false,
      isShowThxDollarList: false,
      targetShowThxDollarIdx: 0,

      showJobRotation: () => {
        gnb.showMyBoxList = false;
        pop.isShowJobRotation = true;
      },
      hideJobRotation: () => {
        sessionStorage.setItem("gISJ", "Y");
        pop.isShowJobRotation = false;
      },

      showThanksDollar: (n) => {
        if (typeof n == "number" || typeof n == "string") {
          pop.targetShowThxDollarIdx = n;
        } else {
          pop.targetShowThxDollarIdx = 0;
        }
        gnb.showMyBoxList = false;
        pop.isShowThxDollarView = false;
        pop.isShowThxDollarList = false;
        if (gnb.info.thanks.is_new == "Y") {
          pop.isShowThxDollarView = true;
        } else {
          pop.isShowThxDollarList = true;
        }
      },
      hideThanksDollar: () => {
        pop.isShowThxDollarView = false;
      },
      hideThxDollarList: () => {
        pop.isShowThxDollarList = false;
      },
      showProfileMod: () => {
        gnb.showMyBoxList = false;
        pop.isShowProfileMod = true;
      },
      hideProfileMod: () => {
        pop.isShowProfileMod = false;
        gnb.getGNBInfo();
      },
    });

    const roll = reactive({
      isInited: false,
      isLoaded: false,
      list: [],

      rollId: null,
      interval: 4000,
      prev: 0,
      curr: 0,
      next: 0,

      is_reverse: false,

      moveLink: (idx) => {
        router.push({
          name: "CARROTZone-NoticeView-idx",
          params: {
            idx: idx,
          },
        });
      },

      doSearch: () => {
        let params = {
          page: 1,
          rows: 3,
        };
        axios
          .get("/rest/carrotzone/noticeList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              roll.list = res.data.list;
              roll.rollInit();
            } else {
              console.log(res.data);
            }
          });
      },

      //롤링 배너 초기화
      rollInit: (newinterval) => {
        if (parseInt(newinterval) > 0) {
          roll.interval = newinterval;
        }
        if (roll.rollId) {
          clearTimeout(roll.rollId);
        }

        if (roll.list.length == 1) {
          roll.prev = roll.list[0]["idx"];
          roll.curr = roll.list[0]["idx"];
          roll.next = roll.list[0]["idx"];
        } else if (roll.list.length == 2) {
          roll.prev = roll.list[0]["idx"];
          roll.curr = roll.list[1]["idx"];
          roll.next = roll.list[0]["idx"];
        } else if (roll.list.length == 3) {
          roll.prev = roll.list[0]["idx"];
          roll.curr = roll.list[1]["idx"];
          roll.next = roll.list[2]["idx"];
        } else {
          roll.prev = 0;
          roll.curr = 0;
          roll.next = 0;
        }

        roll.isLoaded = true;

        roll.rollId = setTimeout(roll.rolling, roll.interval);
        roll.isInited = true;
      },

      rolling: () => {
        let tmp = roll.prev;
        roll.prev = roll.curr;
        roll.curr = roll.next;
        roll.next = tmp;

        setTimeout(roll.rolling, roll.interval);
      },
    });

    const gnb = reactive({
      showMyBoxList: false,
      isToolbarColor: false,
      isLoaded: false,
      isLead: false,
      isPlanningTeam: false,
      isCEO: false,
      gnb_menu_cat1: false,
      gnb_menu_cat2: false,
      gnb_menu_cat3: false,
      gnb_menu_cat4: false,
      gnb_menu_cat5: false,
      gnb_menu_cat6: false,
      gnb_menu_cat7: false,
      gnb_menu_cat8: false,
      old_carrot_link:
        "http://old.carrotians.net/intranet_snew/logproc_old.php?uid=" +
        btoa(store.state.idx_staff),
      cms_link: "https://old.carrotians.net/intranet_snew/sub_cms.php",
      old_mailplug_link: "https://mail.carrotglobal.com/member/login",
      selected: "",
      selected_admin: "",
      idx_hq: 0,
      info: {
        photouri: "@/assets/img/avatar.png",
      },
      rotation: {
        is_show_jobrotation:false
      },


      gnbMenuReset: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat1: () => {
        gnb.gnb_menu_cat1 = true;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat2: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = true;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat3: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = true;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat4: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = true;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat5: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = true;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat6: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = true;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = false;
      },

      gnbMenuCat7: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = true;
        gnb.gnb_menu_cat8 = false;
      },
      gnbMenuCat8: () => {
        gnb.gnb_menu_cat1 = false;
        gnb.gnb_menu_cat2 = false;
        gnb.gnb_menu_cat3 = false;
        gnb.gnb_menu_cat4 = false;
        gnb.gnb_menu_cat5 = false;
        gnb.gnb_menu_cat6 = false;
        gnb.gnb_menu_cat7 = false;
        gnb.gnb_menu_cat8 = true;
      },

      goSMS: () => {
        window.open("https://ums.smartuc.kr/common/login.do", "_blank");
      },

      goMail: () => {
        window.open("https://outlook.office.com/mail", "_blank");
      },

      goMailPlug: () => {
        window.open("https://mail.carrotglobal.com/member/login", "_blank");
      },

      goCTM: () => {
        window.open(
          "https://ctm.carrotenglish.com/act/outlogin?email=" +
            store.state.email,
          "_blank"
        );
      },

      goITM: () => {
        window.open(
          "https://hq.carrotenglish.net/auth/common/directLink?uid=" +
            store.state.email,
          "_blank"
        );
      },

      goSPAC: () => {
        window.open(
          `https://admin.thespac.net/connectionHQ?email=${store.state.email}`,
          "_blank"
        );
      },
      goHRD: () => {
        window.open(
          "https://hrd-old.carrotians.net/act/outlogin?email=" +
            store.state.email,
          "_blank"
        );
      },
      goNewHRD: () => {
        window.open(
          "https://hrd.carrotians.net/connectionHQ?email=" + store.state.email,
          "_blank"
        );
      },
      goDelivery: () => {
        window.open(
          "https://delivery.carrotians.net/rest/info/authLogin?uid=" +
            store.state.email,
          "_blank"
        );
      },
      goDISC: () => {
        window.open(
          `https://old.carrotians.net/intranet_snew/logproc_old.php?uid=${btoa(
            store.state.idx_staff
          )}&goUrl=F_disc_list`,
          "_blank"
        );
      },
      changeStaff: () => {
        // staff index
        console.log(gnb.idx_hq);
      },

      doLogout: () => {
        axios.get("/rest/personal/logout", { params: {} });
        //로그아웃 호출 결과와 관계없이 로그아웃 처리.
        store.commit("clearUserInfo");
        sessionStorage.removeItem("gISF");
        router.push("/login");
      },

      moveDoc: (tp) => {
        console.info(tp);
        router.push({
          name: "documents-AllDocumentList",
          params: {
            doc_type: tp,
          },
        });
      },

      getJobRotationInfo: () => {
        let params = {};
        axios.get("/rest/personal/getJobRatationInfo", { params: params }).then((res) => {
          if (res.data.err == 0) {
            gnb.rotation = res.data;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      moveExternalLink: (url) => {
        window.open(url);
      },
      moveLink: (name) => {
        gnb.showMyBoxList = false;
        router.replace({
          name: name,
        });
      },
      getGNBInfo: () => {
        axios.get("/rest/personal/getGNBInfo", { params: {} }).then((res) => {
          if (res.data.err == 0) {
            gnb.info = res.data;

            gnb.isLoaded = true;
            if (history.state.current.indexOf("login") < 0) {
              if (
                gnb.info.is_show_jobrotation == "Y" &&
                location.href.includes("main")
              ) {
                let ss = sessionStorage.getItem("gISJ");
                let modalCloseTime = localStorage.getItem("modalCloseTime");
                let closeModal = localStorage.getItem("closeModal");
                let today = new Date();

                const yyyy = today.getFullYear();
                const mm =
                  today.getMonth() + 1 > 9
                    ? today.getMonth() + 1
                    : `0${today.getMonth() + 1}`;
                const dd =
                  today.getDate() > 9 ? today.getDate() : `0${today.getDate()}`;

                if (ss !== "Y") {
                  if (
                    modalCloseTime !== `${yyyy}-${mm}-${dd}` &&
                    parseInt(closeModal) !== 0
                  ) {
                    pop.isShowJobRotation = true;
                  } else if (
                    modalCloseTime !== `${yyyy}-${mm}-${dd}` &&
                    parseInt(closeModal) === 0
                  ) {
                    pop.isShowJobRotation = true;
                  } else if (
                    modalCloseTime === `${yyyy}-${mm}-${dd}` &&
                    parseInt(closeModal) !== 0
                  ) {
                    pop.isShowJobRotation = true;
                  } else if (!modalCloseTime) {
                    pop.isShowJobRotation = true;
                  } else {
                    pop.isShowJobRotation = false;
                  }
                }
              }
            }

            store.commit("setGNB", gnb.info);

            if (roll.isInited == false) {
              roll.doSearch();
            }

            gnb.checkCSS();
          } else {
            if (res.data.err == 201) {
              if (!store.state.isToastVisible) {
                store.commit("setisToastVisible", true);
                toast.error(
                  "보안을 위해 로그아웃 되었습니다. 다시 로그인해 주세요.",
                  {
                    onClose: () => {
                      store.commit("setisToastVisible", false);
                    },
                  }
                );
              }

              gnb.doLogout();
            }
          }
        });
      },

      checkCSS: () => {
        window.addEventListener("scroll", function () {
          // console.log(e.scrollY);
          if (location.href.includes("main")) {
            if (window.scrollY >= 573) {
              gnb.isToolbarColor = false;
            } else {
              gnb.isToolbarColor = true;
            }
          }
        });
        if (location.href.includes("main")) {
          gnb.isToolbarColor = true;
        } else {
          gnb.isToolbarColor = false;
        }
      },
    });

    const banner = reactive({
      isVisible: false,
      close: () => {
        const today = new Date();
        const saveDay = today.getDate();

        cookies.cookies.set("today", saveDay);
        console.log(cookies.cookies.get("today"));
        banner.isVisible = false;
      },
      check: () => {
        const today = new Date();
        const saveDay = today.getDate();
        if (saveDay != cookies.cookies.get("today")) {
          banner.isVisible = true;
        }
      },
    });
    onUnmounted(() => {
      // UnMounted
    });

    const onstaff = reactive({
      find_staff: "",
      last_find: "",
      list: [],
      showPop: false,
      prevConnectionTime: new Date(),
      setConnectionData: false,
      total_on: 0,

      showAll: () => {
        window.open(
          "/emp_number_intranet",
          "pop",
          "top=10, left=10, width=1080, height=800, status=no, menubar=no, toolbar=no, resizable=no"
        );
      },
      closed: () => {
        //재호출을 하지 않더라도 리스트를 보여줄 수 있도록 가져온 리스트는 초기화하지 않는다.
        onstaff.last_find = "";
        onstaff.showPop = false;
      },
      checkStaff: () => {
        var curTimeStamp = new Date();
        if (
          onstaff.setConnectionData &&
          onstaff.prevConnectionTime.getTime() + 5000 > curTimeStamp.getTime()
        ) {
          //두번째 호출시 부터는 이전 호출 시각을 비교하여 5초 이내이면 패스한다.
          onstaff.showPop = true;
          return;
        }
        let param = {
          params: {
            tmr: onstaff.find_staff,
            idx_staff: store.state.idx_staff,
          },
        };
        axios.get("/rest/documents/connection_staff_all", param).then((res) => {
          if (res.data.err == 0) {
            //첫번째 호출시에는 무조건 가져오도록 처리한다.
            if (!onstaff.setConnectionData) onstaff.setConnectionData = true;
            onstaff.prevConnectionTime = new Date();
            onstaff.list = res.data.list;
            onstaff.list.sort((a, b) => {
              return a.is_on > b.is_on ? -1 : 0;
            });
            onstaff.total_on = 0;
            for (let item of onstaff.list) {
              if (item.is_on == "Y") {
                onstaff.total_on++;
              }
            }
            onstaff.showPop = true;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      onStaffTotal: () => {
        let param = {
          params: {
            tmr: onstaff.find_staff,
            idx_staff: store.state.idx_staff,
          },
        };
        axios.get("/rest/documents/connection_staff_all", param).then((res) => {
          if (res.data.err == 0) {
            //첫번째 호출시에는 무조건 가져오도록 처리한다.
            if (!onstaff.setConnectionData) onstaff.setConnectionData = true;
            onstaff.prevConnectionTime = new Date();
            onstaff.list = res.data.list;
            onstaff.total_on = 0;
            for (let item of onstaff.list) {
              if (item.is_on == "Y") {
                onstaff.total_on++;
              }
            }
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });
    onMounted(() => {
      // Mounted
      gnb.checkCSS();
      onstaff.onStaffTotal();
      banner.check();
      gnb.getJobRotationInfo();
      if (history.state.current.indexOf("login") < 0) {
        gnb.getGNBInfo();
      }
    });

    onUnmounted(() => {
      // UnMounted
    });

    return { gnb, pop, roll, onstaff, store, banner, cookies };
  },
};
</script>
<style lang="scss" scoped>
.gnb-staff {
  width: 100px;
  height: 20px;
}
.connected_staff {
  width: 295px;
  min-height: 200px;
  max-height: 800px;
  background-color: #fff;
  overflow-y: hidden;
  margin-top: -30px;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  padding: 14px;
  padding-top: 25px;
  //margin-left: 94px;
  .staff-list {
    text-align: center;
    color: #05123a;
    padding: 13px 11px;
    border-bottom: 1px solid #f4f6f8;
  }
  .staff-info.on {
    border-bottom: 1px solid #f4f6f8;
    padding: 13px 11px;
    color: #2c3046;
  }
  .staff-box {
    max-height: 685px;
    overflow-y: auto;
  }
  .staff-info {
    border-bottom: 1px solid #f4f6f8;
    padding: 13px 11px;
    color: #d8d8d8;
  }
  .icon-dollar {
    float: right;
    padding-top: 10px;
    cursor: pointer;
  }
}
.main-navi-wrap .main-gnb-menu .gnb-wrap {
  .oldcarrot {
    color: #555 !important;
  }
  .my-box-bg {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.05);
    position: fixed;
    left: 0;
    top: 40px;
  }
  .gnb-mid-wrap .gnb-mid .logo .main-line-notice {
    .notice-list {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        position: absolute;
        width: calc(100% - 20px);
        height: 100%;
        top: 100%;
        background-color: #fff;
        &.currentroll {
          top: 0;
          transition: top 0.5s ease-in-out, bottom 0.5s ease-in-out;
        }
        &.prevroll {
          top: -100%;
          transition: top 0.5s ease-in-out, bottom 0.5s ease-in-out;
        }
        &.nextroll {
          top: 100%;
          display: block;
          transition: none;
          // transition: top .5s ease-in-out, bottom .5s ease-in-out;
        }
      }
      &.reverse li {
        &.prevroll {
          transition: none;
        }
        &.nextroll {
          transition: top 0.5s ease-in-out, bottom 0.5s ease-in-out;
        }
      }
    }
    .btn-group {
      position: absolute;
      top: 15px;
      right: 0;
      button {
        width: 20px;
        height: 20px;
        padding: 0;
        text-align: center;
        line-height: 18px;
        border: 0;
        color: #aaa;
      }
    }
  }
}
</style>
